import { useRouteError } from "react-router-dom";
import PageTitle from "../../layout/PageTitle";
import PageContent from "../../layout/PageContent";

export default function Error404Page() {
  return (
    <div id="error-page">
      <PageTitle>404 Not Found</PageTitle>
      <PageContent><p>Oops, you've been sent to a page that doesn't exist. Hit the back button on your browser window.</p></PageContent>
      </div>
  );
}