import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom/client'
import './styles/defaultTheme.css'
import './styles/base.css'
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom"
import Dashboard from './components/Dashboard/Dashboard'
import ErrorPage from './components/pages/ErrorPage/ErrorPage'
import Users from './components/pages/Users/Users'
import Materials from './components/pages/Materials/Materials'
import Material from './components/pages/Material/Material'
import Error404Page from './components/pages/Error404Page/Error404Page'
import Locations from './components/pages/Locations/Locations'
import LoginPage from './components/LoginPage/LoginPage'
import Logout from './components/pages/Logout/Logout'
import AssemblyDashboard from './components/pages/AssemblyDashboard/AssemblyDashboard'
import QuickAssemble from './components/pages/QuickAssemble/QuickAssemble'
import UserContext from './components/Context/UserContext'

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/dashboard/",
    element: <Dashboard />,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "locations",
        element: <Locations />,
      },
      {
        path: "materials",
        element: <Materials />,
      },
      {
        path: "material/:materialId",
        element: <Material />,
      },
      {
        path: "quick-assemble/:materialId",
        element: <QuickAssemble />,
      },
      {
        path: "assembly",
        element: <AssemblyDashboard />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: "*",
        element: <Error404Page />,
      },
    ],
  }
]);

const App = () => {

  const [user, setUser] = useState({})

  const loadUser = useCallback(() => {
    console.log("LOAD USER")
    fetch('/api/auth/me')
    .then(res => {
      if (res.ok) return res.json()
      return res.json().then(data => {
        throw new Error(data.error || data.message || res.statusText) 
      })
    })
    .then(data => setUser(data))
    .catch(err => {
      console.log(err)
      setUser({error: err.message})
    });

  }, [])

  const value = { user, loadUser }

  return (
    <>
      <UserContext.Provider value={value}>
        <RouterProvider router={router} />
      </UserContext.Provider>
    </>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root')).render(
  <App />
);

