import './Dashboard.css'
import { Link, useLocation, useOutlet, useNavigate } from "react-router-dom";
import logo from "./images/etree-logo-transparent.png"
import logoWhite from "./images/etree-logo-white.png"
import PageTitle from "../layout/PageTitle";
import PageContent from "../layout/PageContent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faExternalLink, faGauge, faLocation, faPuzzlePiece, faSignOut, faUser, faUserTie, faWrench } from '@fortawesome/free-solid-svg-icons'
import { useContext, useEffect, useState } from 'react';
import LoadingMessage from '../layout/LoadingMessage/LoadingMessage';
import UserContext from '../Context/UserContext';

function Dashboard(props) {

    const outlet = useOutlet()
    const location = useLocation()
    const navigate = useNavigate()

    const { user, loadUser } = useContext(UserContext)

    // load the user when dashboard mounts
    useEffect(() => {
        loadUser()
    }, [])

    const mobileWidthPx = 800

    const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidthPx)
    const [showSidebar, setShowSidebar] = useState(true)

    // update isMobile based on screen size
    const updateMedia = () => {
        if (window.innerWidth < mobileWidthPx) {
            setIsMobile(true)
            setShowSidebar(false)
            return
        }
        setIsMobile(false)
        setShowSidebar(true)
    }
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
      }, []);
    useEffect(() => {
        if (isMobile) setShowSidebar(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    const toggleSideBar = () => {
        setShowSidebar(!showSidebar)
    }
    if (!user) return (<LoadingMessage />)
    if (user?.error) navigate("/")
    return (
        <div id="Dashboard">
            { showSidebar && isMobile && <div id='close-sidebar' onClick={toggleSideBar}></div>}
            <div id="main-sidebar-wrap" className={ (isMobile ? "mobile" : "") + (showSidebar ? "" : " hidden")}>
                <MainSidebar />
            </div>
            <div id="page-wrap">
                { isMobile &&
                    <div id="mobile-header">
                        <div className='left'>
                            <span
                                className='sidebar-toggle spanButton'
                                onClick={toggleSideBar}
                                role="button"
                                aria-label="Open Navigation Menu">
                                <FontAwesomeIcon icon={faBars}/>
                            </span>
                        </div>
                        <div className='logo'><img alt="CONFLUX" src={logoWhite}/></div>
                        <div className='right'>
                            <span
                                className="spanButton"
                                onClick={() => navigate('/dashboard/logout')}
                                role="button"
                                aria-label="Log out">
                                <FontAwesomeIcon icon={faSignOut}/>
                            </span>
                        </div>
                    </div>
                }
                <Page>{outlet || <Homepage />}</Page>
            </div>
        </div>
    );
}

function Homepage() {
    return (
        <>
            <PageTitle>Dashboard - Assembly Tracker</PageTitle>
            <PageContent>
                <p>Please select an action from the sidebar.</p>
            </PageContent>
        </>
    );
}

function MainSidebar() {

    const { user } = useContext(UserContext)

    return (
        <aside id="MainSidebar">
            <div id="app-name"><span>CONFLUX</span></div>
            <div id="main-logo">
                <img alt="ETREE PRODUCTIONS LTD" src={logo}/>
            </div>
            <div className="user-greeting">
                <p>Welcome, {user.name}</p>
            </div>
            <MainSidebarMenu/>
        </aside>
    );
}

function MainSidebarMenu() {
    return (
        <nav id="MainSidebarMenu">
            <ul>
                <MainSidebarMenuItem faIcon={faGauge} title="Dashboard" link="/dashboard"/>
                <MainSidebarMenuItem faIcon={faUser} title="Users" link="/dashboard/users"/>
                <MainSidebarMenuItem faIcon={faLocation} title="Locations" link="/dashboard/locations"/>
                <MainSidebarMenuItem faIcon={faPuzzlePiece} title="Materials" link="/dashboard/materials"/>
                <MainSidebarMenuItem faIcon={faWrench} title="Assembly" link="/dashboard/assembly"/>
                <MainSidebarMenuItem faIcon={faUserTie} title="Customers" link="/dashboard/customers"/>
                <MainSidebarMenuItem faIcon={faExternalLink} title="Log out" link="/dashboard/logout"/>
            </ul>
        </nav>
    );
}

function MainSidebarMenuItem({title, link, faIcon}) {
    return (
        <li className="MainSidebarMenuItem">
            <Link to={link}>{faIcon && <FontAwesomeIcon icon={faIcon} />}<span>{title}</span></Link>
        </li>
    );
}

function Page(props) {
    return (
        <div id="Page">
            {props.children}
        </div>
    );
}

export default Dashboard
export {

}