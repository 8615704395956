import PageContent from "../../layout/PageContent"
import PageTitle from "../../layout/PageTitle"

const AssemblyDashboard = () => {
    return (
        <div id="AssemblyDashboard">
            <PageTitle>Assembly Dashboard</PageTitle>
            <PageContent>
                <p>Assemble</p>
            </PageContent>
        </div>
    )
}

export default AssemblyDashboard