import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage";
import axios from "axios";

const Logout = () => {
    const navigate = useNavigate();

    useEffect(() => {

        const logout = () => {
            axios.delete('/api/auth/logout').then(res => {
                localStorage.clear();
                navigate("/", { replace: true })
            }).catch(err => {
                console.log(err)
                alert(err.message)
            });
        }
        logout()

    }, [navigate]);

    return <LoadingMessage />;
};

export default Logout