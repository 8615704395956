import './Assemble.css'
import useFetch from "../../../../hooks/useFetch";
import ErrorMessage from "../../../layout/ErrorMessage/ErrorMessage";
import LoadingMessage from "../../../layout/LoadingMessage/LoadingMessage";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

function currencyFormat(num) {
    return '£' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const Assemble = ({materialId, bomId, material}) => {

    const { error, isPending, data:bom, reloadData } = useFetch('/api/bom', {
        bom_id: bomId,
        include_inventory: true,
        location_id: 10
    })

    const locationId = 1 // for testing

    if (!materialId) return <p>No material selected.</p>
    if (!bomId) return <p>No BOM selected.</p>

    return (
        <div id="Assemble">
            <h3>Assemble</h3>
            { error && <ErrorMessage message={error} /> }
            { isPending && <LoadingMessage /> }
            { bom && <AssemblyChecker bom={bom} material={material} locationId={locationId}/> } 
        </div>
    )
}

const AssemblyChecker = ({bom, material, locationId, addToTotalCost}) => {

    const [numberToAssemble, setNumberToAssemble] = useState(0)

    const handleQtyInputChange = (e) => {
        e.preventDefault()
        setNumberToAssemble(e.target.value)
    }

    const handleAssemble = () => {
        if (numberToAssemble === 0) return alert('Enter a number to assemble')
        axios.post('/api/assemble', {
            locationId: 1,
            materialId: material.material_id,
            bomId: bom.bom_id,
            qtyToAssemble: numberToAssemble
        }).then(res => {
            console.log("RESSS", res)
            setNumberToAssemble(0)
        }).catch(err => {
            console.log(err)
            alert(err.message)
        });
    }

    const sumTotalCost = () => {
        let cost = 0
        for (const i in bom.materials) {
            const costPrice = bom.materials[i].material.cost_price
            const costFloat = costPrice ? (parseFloat(costPrice) * bom.materials[i].qty * numberToAssemble) : 0
            cost += costFloat
        }
        return cost
    }

    const totalCost = sumTotalCost()
    const totalSalePrice = (parseFloat(material.sales_price)) ? parseFloat(material.sales_price) * numberToAssemble : 0
    const profit = totalSalePrice - totalCost

    return (
        <div>
            <p>Assembling <b>{material.name} [{material.sku}]</b> using bom <b>{bom.bom_name} [{bom.bom_id}]</b>.</p>
            <label>Enter number to assemble: </label><input type='number' value={numberToAssemble} onChange={(e) => handleQtyInputChange(e)}/>
            <button className="Button" onClick={handleAssemble} disabled={!numberToAssemble || numberToAssemble===0}>Assemble</button>
            <table>
                <tr>
                    <th>Material ID</th>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>BOM QTY</th>
                    <th>Available Inventory</th>
                    <th>Inventory Change</th>
                    <th>Unit Cost</th>
                    <th>Total Cost</th>
                </tr>
                <tr>
                    <td><b>{material.material_id} <span style={numberToAssemble < 0 ? {color: "red"} : {color: "green"}}>{numberToAssemble < 0 ? "(Disassembling)" : "(Assembling)"}</span></b></td>
                    <td>{material.sku}</td>
                    <td>{material.name}</td>
                    <td>1</td>
                    <td></td>
                    <td style={numberToAssemble < 0 ? {color: "red"} : {color: "green"}}>{~~numberToAssemble}</td>
                    <td></td>
                    <td></td>
                </tr>
                {bom.materials.map((bomRow) =>
                    <InventoryChecker 
                        bomRow={bomRow}
                        numberToAssemble={~~numberToAssemble}
                        locationId={locationId}
                    />
                )}
            </table>
            <p>Total Cost: {currencyFormat(totalCost)}</p>
            <p>Total Revenue: {currencyFormat(totalSalePrice)}</p>
            <p>Profit: {currencyFormat(profit)} ({((profit/totalSalePrice)*100).toFixed(2)}%)</p>
        </div>
    )
}

const InventoryChecker = ({bomRow, numberToAssemble, locationId}) => {

    const { error:err, isPending, data:inventory, reloadData } = useFetch('/api/inventory', {
        material_id: bomRow.material_id,
        locationId: locationId
    })

    useEffect(() => {
        if (numberToAssemble === 0) {
            reloadData()
            console.log("dataReloaded")
        }
    }, [numberToAssemble])

    const inventoryChange = -1 * parseInt(bomRow.qty) * parseInt(numberToAssemble)
    const inventoryOnHand = inventory && (inventory[0] ? inventory[0].qty_on_hand : 0)
    
    if (err) return <span>Error</span>
    if (isPending || !inventory) return <span>Loading...</span>
    return (
        <tr>
            <td>{bomRow.material_id} <a target='_blank' rel="noreferrer" href={'/dashboard/material/' + bomRow.material_id}><FontAwesomeIcon icon={faUpRightFromSquare}/></a></td>
            <td>{bomRow.material.sku}</td>
            <td>{bomRow.material.name}</td>
            <td>{bomRow.qty}</td>
            <td style={inventoryOnHand < -1*inventoryChange ? {color: "red"} : {color: "green"}}>{inventoryOnHand}</td>
            <td style={inventoryChange < 0 ? {color: "red"} : {color: "green"}}>{inventoryChange}</td>
            <td>{currencyFormat(bomRow.material.cost_price ? parseFloat(bomRow.material.cost_price) : 0)}</td>
            <td>{currencyFormat(bomRow.material.cost_price ? -1 * parseFloat(bomRow.material.cost_price) * parseFloat(inventoryChange): 0)}</td>
        </tr>
    )
}


export default Assemble