import axios from "axios";
import { useState } from "react";
import './LoginPage.css'
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../layout/ErrorMessage/ErrorMessage";

function LoginPage() {
    return (
        <div id="LoginPage">
            <h1>Conflux System</h1>
            <p>This is an internal system for employees of Etree Productions Limited.</p>
            <h2>Login</h2>
        <LoginForm />
       </div>
    )
}

const LoginForm = (props) => {

    const navigate = useNavigate()

    const [inputs, setInputs] = useState({});
    const [errorMessage, setErrorMessage] = useState(null)

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        axios.post('/api/auth/login', inputs).then(res => {
                console.log(res)
                navigate('/dashboard')
            }).catch(err => {
                console.log(err)
                setErrorMessage(err.response.data?.message || err.message)
            })
    }
    return (
        <div id="LoginForm">
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email</label>
                <input
                    name="email"
                    type="email"
                    value={inputs.email || ""} 
                    onChange={handleChange}
                />
                <label htmlFor="password">Password</label>
                <input
                    name="password"
                    type="password"
                    value={inputs.password || ""} 
                    onChange={handleChange}
                />
                <input type="submit" value="Log in"/>
            </form>
        </div>
    );
}

export default LoginPage