import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './Materials.css'
import useFetch from '../../../hooks/useFetch'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useState, useMemo } from 'react'
import ButtonBar from '../../layout/ButtonBar/ButtonBar'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

const Materials = () => {

    const navigate = useNavigate()

    const { error, isPending, data:materials, reloadData } = useFetch('/api/material')

    // Toggle to display NewMaterialForm
    const [showNewMaterialForm, setShowNewMaterialForm] = useState(false)
    const toggleNewMaterialForm = () => {
        setShowNewMaterialForm(!showNewMaterialForm)
    }

    // Retrieve the selected rows from the grid
    const [selectedRows, setSelectedRows] = useState([])
    const oneSelected = (selectedRows.length===1)
    const selectedHasDefaultBomId = selectedRows[0]?.default_bom_id != null

    // Navigate to the Quick Assemble page
    const handleQuickAssembleClick = () => {
        navigate('/dashboard/quick-assemble/' + selectedRows[0].material_id + '?bomId=' + selectedRows[0].default_bom_id)
    }

    return (
        <div id="Materials">
            <PageTitle>Materials</PageTitle>
            <PageContent>
                <ButtonBar>
                    <button className="Button" onClick={toggleNewMaterialForm}>New Material</button>
                    <button className="Button" onClick={handleQuickAssembleClick} disabled={!oneSelected || !selectedHasDefaultBomId}>Quick Assemble</button>
                </ButtonBar>
                { showNewMaterialForm && <NewMaterialForm reloadTable={reloadData}/> }
                { error && <ErrorMessage message={error} /> }
                { isPending && <LoadingMessage /> }
                { materials && <MaterialTable materials={materials} setSelectedRows={setSelectedRows} /> }
            </PageContent>
        </div>
    );
}

const MaterialTable = ({materials, setSelectedRows}) => {

    const navigate = useNavigate()

    // Row Data: The data to be displayed.
    const rowData = materials
    const [gridApi, setGridApi] = useState(null)
    const [searchTerms, setSearchTerms] = useState("")

    const onGridReady = useCallback((params) => {
        setGridApi(params.api)
    }, [])

    // Apply settings across all columns
    const defaultColDef = useMemo(() => ({
        filter: true,
    }))

    const handleRowClicked = (e) => {
        const material_id = e.data.material_id
        console.log(material_id)
        navigate("/dashboard/material/"+material_id)
    }
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { headerName: "SKU", field: "sku",
            checkboxSelection: true,
            headerCheckboxSelection: true
        },
        { headerName: "Name", field: "name" },
        { headerName: "Category", field: "category" },
        {
            headerName: "Vendable",
            field: "is_vendable",
            editable:false,
            cellRendererParams: { disabled: true },
         }
    ])

    const onSelectionChanged = () => {
        const selectedData = gridApi.getSelectedRows()
        setSelectedRows(selectedData)
    }

    // Sizing settings for the grid
    const autoSizeStrategy = {
        type: 'fitGridWidth',
        defaultMinWidth: 100,
        columnLimits: [
            { colId: 'sku', minWidth: 200 },
            { colId: 'name', minWidth: 500 },
            { colId: 'category', minWidth: 250 },
            { colId: 'is_vendable', minWidth: 150 }
        ]
    }

    const rowSelection = 'multiple';

    return (
        <div className="ag-theme-quartz" style={{ height: 600 }}>
            <input type='text' value={searchTerms} onChange={(e) => setSearchTerms(e.target.value)} placeholder='Search..'/>
            <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                pagination={true}
                defaultColDef={defaultColDef}
                rowSelection={rowSelection}
                onSelectionChanged={onSelectionChanged}
                onGridReady={onGridReady}
                onRowClicked={handleRowClicked}
                autoSizeStrategy={autoSizeStrategy}
                quickFilterText={searchTerms}
            />
        </div>
    );
   
}

const NewMaterialForm = ({reloadTable}) => {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        axios.post('/api/material', inputs).then(res => {
                console.log(res)
                setInputs({})
                reloadTable()
            }).catch(err => {
                console.log(err)
                alert(err.response.data)
            });
    }
    return (
        <div id="NewMaterialForm">
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">SKU</label>
                <input
                    name="sku"
                    type="text"
                    value={inputs.sku || ""} 
                    onChange={handleChange}
                />
                <label htmlFor="name">Name</label>
                <input
                    name="name"
                    type="text"
                    value={inputs.name || ""} 
                    onChange={handleChange}
                />
                <label htmlFor="email">Description</label>
                <input
                    name="description"
                    type="text"
                    value={inputs.description || ""} 
                    onChange={handleChange}
                />
                <input type="submit"/>
            </form>
        </div>
    );
}

export default Materials