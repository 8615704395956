import { useState, useEffect, useCallback } from 'react';

const useFetch = (url, params = {}) => {
  const [state, setState] = useState({ data: null, isPending: true, error: null })
  // reloads the data
  const reloadData = () => {
    // keep old data until new data has arrived
    setState({ data: state.data, isPending: true, error: null })
    loadData()
  }
  const loadData = useCallback(() => {
    // converts optional object into search params and appends to url
    const searchParams = new URLSearchParams(params).toString()
    var queryString = url + (searchParams ? '?' + searchParams : '')
    fetch(queryString)
    .then(res => {
      console.log("FETCHING: ", queryString)
      if (res.ok) return res.json()
      console.log(res)
      return res.json().then(data => {
        throw new Error(data.error || data.message || res.statusText) 
      })
    })
    .then(data => {
      console.log("RESULT: ", data)
      setState({ data: data, isPending: false, error: null })
    })
    .catch(error => {
      console.log(error)
      setState({ data: null, isPending: false, error: error.message })
    })
  }, [url])
  useEffect(() => {
    // reset values so components re-render properly
    setState({ data: null, isPending: true, error: null })
    loadData()
  }, [url, loadData])
  return { ...state, reloadData }
}
 
export default useFetch