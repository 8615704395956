import { useParams } from 'react-router-dom'
import PageContent from '../../layout/PageContent'
import PageTitle from '../../layout/PageTitle'
import './Material.css'
import useFetch from '../../../hooks/useFetch'
import ErrorMessage from '../../layout/ErrorMessage/ErrorMessage'
import LoadingMessage from '../../layout/LoadingMessage/LoadingMessage'
import Inventory from './Inventory/Inventory'
import Boms from './Boms/Boms'
import Collapsible from '../../layout/Collapsible/Collapsible'
import Assemble from './Assemble/Assemble'

const Material = () => {

    const params = useParams()
    const materialId = params.materialId

    const { error, isPending, data:material, reloadData:reloadMaterial } = useFetch('/api/material?id=' + materialId)

    return (
        <div id="Material">
            <PageTitle>Material - {materialId}</PageTitle>
            <PageContent>
                { error && <ErrorMessage message={error} /> }
                { isPending && <LoadingMessage /> }
                { material && <MaterialInfo material={material} reloadMaterial={reloadMaterial} /> }
            </PageContent>
        </div>
    )
}

const MaterialInfo = ({material, reloadMaterial}) => {
    return (
        <div id="Material">
            <Collapsible title="Material Information">
                <form>
                    <label>Material ID: {material.material_id} <small><i>- (System generated ID - this cannot be changed.)</i></small></label>
                    <input type="text" value={material.material_id} disabled={true}/>
                    <label>Material Name</label>
                    <input type="text" value={material.name} />
                    <label>Material SKU</label>
                    <input type="text" value={material.sku} />
                    <label>Material Description</label>
                    <input type="text" value={material.description} />
                    <input type='submit' value='Save changes' />
                </form>
            </Collapsible>
            <Collapsible title="Inventory">
                <Inventory materialId={material.material_id} />
            </Collapsible>
            <Collapsible title="Bill of Materials" defaultClosed={true}>
                <Boms material={material} reloadMaterial={reloadMaterial} />
            </Collapsible>
            <Collapsible title="Assemble" defaultClosed={true}>
                <Assemble materialId={material.material_id} bomId={material.default_bom_id} material={material} />
            </Collapsible>
        </div>
    )
}

export default Material