import { useEffect, useState } from "react"
import PageContent from "../../layout/PageContent"
import PageTitle from "../../layout/PageTitle"
import "./QuickAssemble.css"
import Collapsible from "../../layout/Collapsible/Collapsible"
import { useParams, useSearchParams } from "react-router-dom"
import useFetch from "../../../hooks/useFetch"
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage"
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"

const QuickAssemble = () => {

    let { materialId } = useParams()
    let [searchParams, setSearchParams] = useSearchParams()
    const bomId = searchParams.get("bomId")
    
    const {error, isPending, data:material, reloadData:reloadMaterial} = useFetch('/api/material', {
        id: materialId
    })
    const {error:errorBom, isPending:isPendingBom, data:bom, reloadData:reloadBom} = useFetch('/api/bom', {
        bom_id: bomId
    })

    const [numberInput, setNumberInput] = useState(0)

    useEffect(() => {
        if ((bomId === "default" || bomId == null) && material?.default_bom_id) {
            setSearchParams({bomId: material.default_bom_id})
        }
    }, [bomId, material])

    const [submitStatus, setSubmitStatus] = useState("idle") // "idle, submitting, complete, error"


    useEffect(() => {
        if (submitStatus === "complete") {
            // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
            const timeoutId = setTimeout(() => {
                setSubmitStatus("idle");
            }, 2000);
        
            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeoutId);
        }
    }, [submitStatus])

    const handleAssemble = () => {
        if (numberInput === 0) return alert('Enter a number to assemble')
        setSubmitStatus("submitting")
        axios.post('/api/assemble', {
            locationId: 1,
            materialId: materialId,
            bomId: bomId,
            qtyToAssemble: numberInput
        }).then(res => {
            setNumberInput(0)
            setSubmitStatus("complete")
        }).catch(err => {
            console.log(err)
            setSubmitStatus("error")
            alert(err.message)
        });
    }

    if (isPending || isPendingBom) return <LoadingMessage/>
    if (errorBom) return <ErrorMessage message={errorBom}/>
    if (error) return <ErrorMessage message={error}/>
    if (!(bom?.material_id === materialId)) return <ErrorMessage message={"The selected bom does not assemble the requested material."}/>

    console.log(bom)

    return (
        <div id="QuickAssemble">
            <PageTitle>Quick Assemble</PageTitle>
            <p><b>Assembling:</b> [{material.sku}] {material.name} <a target='_blank' rel="noreferrer" href={'/dashboard/material/' + material?.material_id}><FontAwesomeIcon icon={faUpRightFromSquare}/></a></p>
            <p><b>BomID:</b> [{bom?.bom_id}] {bom?.bom_name}</p>
            <PageContent>
                {bom && (bom?.material_id === materialId) && <NumberKeypad numberInput={numberInput} setNumberInput={setNumberInput} handleSubmit={handleAssemble} submitStatus={submitStatus}/>}
                <Collapsible title="Assembly History">
                    <p>This feature is coming soon...</p>
                </Collapsible>
            </PageContent>
        </div>
    )
}

const NumberKeypad = ({numberInput, setNumberInput, handleSubmit, submitStatus}) => {

    const handleNumberClick = (e) => {
        const input = e.target.value
        if (input === "CLR") return setNumberInput(0)
        const newInput = parseInt("" + numberInput + input)
        setNumberInput(newInput)
    }

    const handleInputChange = (e) => {
        setNumberInput(e.target.value)
    }

    const handleDisassembleSubmit = () => {
        setNumberInput(numberInput * -1)
        handleSubmit()
    }


    return (
        <div className={"NumberKeypad status-" + submitStatus}>
            <input type="number" value={numberInput} onChange={handleInputChange}/>
            <div className="number-buttons">
                {[1,2,3,4,5,6,7,8,9,"CLR",0,"00"].map((num) => <button className="number-button" onClick={handleNumberClick} value={num} key={num}>{num}</button>)}
            </div>
            <button className="submit-assemble" onClick={handleSubmit}>Assemble</button>
            <button className="submit-disassemble" onClick={handleDisassembleSubmit}>Disassemble</button>
        </div>
    )
}

export default QuickAssemble