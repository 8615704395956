import PageTitle from "../../layout/PageTitle";
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid
import { useMemo, useState, useCallback } from "react";
import { ButtonBar } from "../../layout/ButtonBar/ButtonBar";
import PageContent from "../../layout/PageContent";
import useFetch from "../../../hooks/useFetch";
import axios from 'axios';
import './Locations.css';
import LoadingMessage from "../../layout/LoadingMessage/LoadingMessage";
import ErrorMessage from "../../layout/ErrorMessage/ErrorMessage";

const Locations = () => {

    const { error, isPending, data:locations, reloadData:reloadLocationTable } = useFetch('/api/location')

    const [showNewLocationForm, setShowNewLocationForm] = useState(false)
    const toggleNewLocationForm = () => {
        setShowNewLocationForm(!showNewLocationForm)
    }

    // retrieve the selected rows from the grid
    const [selectedLocations, setSelectedLocations] = useState([])
    // boolean for if locations are selected
    const locationsAreSelected = (selectedLocations.length > 0)
    // handle when the delete selected locations button is pressed
    const handleArchiveSelectedLocations = () => {
        // err here
        let locationIds = selectedLocations.map(({ location_id }) => location_id)
        axios.post('/api/location/archive', locationIds).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
            alert(err.message)
        });

    }

    return (
        <div id="Locations">
            <PageTitle>Locations</PageTitle>
            <PageContent>
                <ButtonBar>
                    <button className="Button" onClick={toggleNewLocationForm}>New Location</button>
                    <button className="Button" onClick={handleArchiveSelectedLocations} disabled={!locationsAreSelected}>Archive Location(s)</button>
                </ButtonBar>
                { showNewLocationForm && <NewLocationForm reloadLocationTable={reloadLocationTable}/> }
                { error && <ErrorMessage message={error} /> }
                { isPending && <LoadingMessage /> }
                { locations && <LocationsTable data={locations} setSelectedLocations={setSelectedLocations}/> }
            </PageContent>
        </div>
    );
}

const LocationsTable = (props) => {

    // Row Data: The data to be displayed.
    const rowData = props.data
    const [gridApi, setGridApi] = useState(null);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    // Apply settings across all columns
    const defaultColDef = useMemo(() => ({
        filter: true,
    }));
    
    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        { 
            headerName: "Id",
            field: "location_id",
            checkboxSelection: true,
            headerCheckboxSelection: true

        },
        { headerName: "Name", field: "name" },
    ]);

    const onSelectionChanged = () => {
        const selectedData = gridApi.getSelectedRows();
        props.setSelectedLocations(selectedData)
    };

    const rowSelection = 'multiple';

    return (
        <div className="ag-theme-quartz" style={{ height: 400 }}>
        <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            pagination={true}
            defaultColDef={defaultColDef}
            rowSelection={rowSelection}
            onSelectionChanged={onSelectionChanged}
            onGridReady={onGridReady}
        />
        </div>
    );
   
}

const NewLocationForm = (props) => {
    const [inputs, setInputs] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        axios.post('/api/location', {location_name: inputs.name}).then(res => {
                console.log(res)
                alert("New location created.")
                props.reloadLocationTable()
            }).catch(err => {
                console.log(err)
                alert(err.message)
            });
    }
    return (
        <div id="NewLocationForm">
            <form onSubmit={handleSubmit}>
                <label htmlFor="name">Name</label>
                <input
                    name="name"
                    type="text"
                    value={inputs.name || ""} 
                    onChange={handleChange}
                />
                <input type="submit"/>
            </form>
        </div>
    );
}

export default Locations